<script lang="ts" setup>
import { FetchError } from 'ofetch'
import type { LocationAsRelativeRaw } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { differenceInCalendarDays, differenceInHours } from 'date-fns'
import { formatReservationDateForInput, formatReservationTime } from '~/utils/utc-date'
import type { Agency, JsonLdCollection, VehicleType } from '~/types/api'
import { InternalRouteName } from '~/constants/internal-route-name'

interface ReservationFormProps {
    departureAgency?: string | undefined
    returnAgency?: string | undefined
    category?: string | undefined
    departureTime?: Date | undefined
    returnTime?: Date | undefined
    initialError?: Error | undefined
    formError?: string | undefined
    loading?: boolean | undefined
    contactLink?: string | LocationAsRelativeRaw | undefined
}

interface ReservationFormData {
    availableAgencies: JsonLdCollection<Agency> | undefined
    availableTypes: JsonLdCollection<VehicleType> | undefined
}

// Some Agency props are required for the form to work.
export type ReservationAgency = Agency &
    Required<
        Pick<
            Agency,
            | 'minimumDepartureHoursDelay'
            | 'maximumDepartureDaysDelay'
            | 'minimumReturnHoursDelay'
            | 'maximumReturnDaysDelay'
            | 'openingHoursSpecification'
        >
    >

const { t } = useI18n()
const route = useRoute()
const props = withDefaults(defineProps<ReservationFormProps>(), {
    loading: false,
    contactLink: '/contact' || { name: InternalRouteName.CONTACT_INDEX },
})
const { $apiFetch } = useNuxtApp()

const { data, error } = await useAsyncData<ReservationFormData>('reservation_form', async () => {
    const [availableAgencies, availableTypes] = await Promise.all([
        $apiFetch<JsonLdCollection<ReservationAgency>>('/agencies', {
            method: 'GET',
            query: {
                'exists[openingHoursSpecification]': true,
                'order[slug]': 'ASC',
                'properties[0]': 'mrcAgencyCode',
                'properties[1]': 'id',
                'properties[2]': 'title',
                'properties[3]': 'timezone',
                'properties[4]': 'minimumDepartureHoursDelay',
                'properties[5]': 'minimumReturnHoursDelay',
                'properties[6]': 'maximumDepartureDaysDelay',
                'properties[7]': 'maximumReturnDaysDelay',
                'properties[8]': 'openingHoursSpecification',
            },
        }),
        $apiFetch<JsonLdCollection<VehicleType>>('/vehicle_types', {
            method: 'GET',
            query: {
                'order[position]': 'asc',
                'properties[0]': 'mrcTypeCode',
                'properties[1]': 'id',
                'properties[2]': 'title',
                'properties[3]': 'image',
            },
        }),
    ])
    return {
        availableAgencies,
        availableTypes,
    } as ReservationFormData
})

const departureAgency = ref<string | undefined>(
    props.departureAgency?.toLocaleLowerCase() || useDefaultAgencyState().value?.mrcAgencyCode?.toLocaleLowerCase(),
)
const category = ref<string | undefined>(
    props.category || data.value?.availableTypes?.['hydra:member'][0].mrcTypeCode.toLocaleLowerCase(),
)
const dateRange = ref<(Date | string | undefined)[]>([])

if (props.departureTime) {
    dateRange.value[0] = formatReservationDateForInput(props.departureTime)
}

if (props.returnTime) {
    dateRange.value[1] = formatReservationDateForInput(props.returnTime)
}

const fetchError = computed(() => {
    const initialError = props.initialError as FetchError | undefined

    const errorDescription =
        initialError?.data?.['hydra:description'] || (error.value as FetchError)?.data?.['hydra:description']

    if (errorDescription) return errorDescription
    else if (initialError || error.value) return t('error_fetch.message')

    return undefined
})

const availableAgencies = computed<Array<Agency>>(() => {
    return data.value?.availableAgencies?.['hydra:member'] as Array<Agency>
})

const selectedAgency = computed<Agency | undefined>(() => {
    return availableAgencies.value?.find(
        (agency: Agency) => agency.mrcAgencyCode.toLocaleLowerCase() === departureAgency.value?.toLocaleLowerCase(),
    )
})

const availableTypes = computed<Array<VehicleType>>(() => {
    return data.value?.availableTypes?.['hydra:member'] as Array<VehicleType>
})

const utcDepartureTime = computed(() => {
    if (!dateRange.value?.[0]) {
        return undefined
    }
    return formatReservationTime(new Date(dateRange.value?.[0] as string))
})

const utcReturnTime = computed(() => {
    if (!dateRange.value?.[1]) {
        return undefined
    }
    return formatReservationTime(new Date(dateRange.value?.[1] as string))
})

const hasAgency = computed(() => {
    return departureAgency.value !== undefined
})

const isFilled = computed(() => {
    return selectedAgency.value && dateRange.value[0] && dateRange.value[1]
})

const departureDateErrorMessage = computed(() => {
    const departureDate = dateRange.value?.[0]
    const returnDate = dateRange.value?.[1]

    if (!departureDate || !returnDate) return

    if (departureDate > returnDate) {
        return t('rent_a_car.error.departure_superior_return')
    }

    // MIN DEPARTURE DATE DELAY
    const minimumDepartureHoursDelay = selectedAgency.value?.minimumDepartureHoursDelay || 0

    if (differenceInHours(departureDate, new Date()) < minimumDepartureHoursDelay) {
        return t('rent_a_car.error.departure_minimum_delay', { hours: minimumDepartureHoursDelay })
    }
})

const returnDateErrorMessage = computed(() => {
    const departureDate = dateRange.value?.[0]
    const returnDate = dateRange.value?.[1]

    if (!departureDate || !returnDate) return

    // MIN RETURN DATE DELAY
    const minimumReturnHoursDelay = selectedAgency.value?.minimumReturnHoursDelay || 0

    if (differenceInHours(returnDate, departureDate) < minimumReturnHoursDelay) {
        return t('rent_a_car.error.return_minimum_delay', { hours: minimumReturnHoursDelay })
    }

    // MAX RETURN DATE DELAY
    const maximumReturnDaysDelay = selectedAgency.value?.maximumReturnDaysDelay || 0

    if (differenceInCalendarDays(returnDate, departureDate) > maximumReturnDaysDelay) {
        return t('rent_a_car.error.return_maximum_delay', { days: maximumReturnDaysDelay })
    }
})

const errorMessage = computed(() => {
    return props.formError || departureDateErrorMessage.value || returnDateErrorMessage.value
})

const loading = computed(() => {
    return props.loading || useReservationFormLoadingState().value
})

const searchVehicle = () => {
    // Reset the cart cookie
    useCartCookie().value = undefined
    useReservationFormLoadingState().value = true
    return navigateTo(
        `/reservation/${category.value}-${departureAgency.value}-${utcDepartureTime.value}-${utcReturnTime.value}`,
    )
}
watch(
    () => route.path,
    () => {
        if (!departureAgency.value && useDefaultAgencyState().value?.mrcAgencyCode) {
            departureAgency.value = useDefaultAgencyState().value?.mrcAgencyCode.toLocaleLowerCase()
        }
    },
)

// the selected agency must have the following properties
const agencyError = computed(() => {
    if (selectedAgency.value) {
        const missingProperties = [
            'minimumDepartureHoursDelay',
            'maximumDepartureDaysDelay',
            'minimumReturnHoursDelay',
            'maximumReturnDaysDelay',
            'openingHoursSpecification',
        ].filter((prop) => {
            return !(prop in (selectedAgency.value as ReservationAgency))
        })

        if (missingProperties.length) {
            Sentry.captureException(
                new Error(
                    `Reservation form: the agency ${selectedAgency.value?.title} missing properties: ${missingProperties.join(', ')}`,
                ),
            )

            return t('rent_a_car.agency_configuration_error')
        }
    }

    return undefined
})

const baseURL = useRuntimeConfig().public.baseURL || 'https://mingat.com'

if (error.value) {
    useComponentError(new Error('Impossible to fetch reservation form data from API.'))
}
</script>

<template>
    <form v-if="!fetchError" :class="$style.root">
        <div :class="$style.title" class="text-h4 cap-black-italic">{{ $t('reservation_form.title') }}</div>
        <VRadioButtonGroup
            v-if="availableTypes"
            v-model="category"
            :options="
                availableTypes.map((type) => ({
                    id: type.mrcTypeCode.toLocaleLowerCase(),
                    label: type.title,
                    value: type.mrcTypeCode.toLocaleLowerCase(),
                    image: type.image,
                }))
            "
            :class="$style.type"
            name="category"
            required
        />
        <VButton
            v-if="contactLink"
            :class="$style.contact"
            tertiary
            :label="$t('single_trip_contact_us')"
            :to="contactLink"
        >
            <template #icon>
                <SvgIcon name="arrow-small-right" viewBox="0 0 24 24" width="24" height="24" />
            </template>
        </VButton>
        <fieldset :class="$style.main">
            <VAgencySelect
                id="departure-agency"
                v-model="departureAgency"
                :class="$style.agencies"
                :error="agencyError"
                value-field="mrcAgencyCode"
                :label="$t('departure_agency')"
                :required="true"
            />
            <VReservationDateRange v-model="dateRange" :disabled="!hasAgency || agencyError" :agency="selectedAgency" />
            <VButton
                size="xl"
                :class="$style.submit"
                filled
                theme="yellow"
                :loading="loading"
                :disabled="!isFilled || !!errorMessage"
                @click.prevent="searchVehicle"
            >
                <template #icon>
                    <SvgIcon name="search" viewBox="0 0 24 24" width="24" height="24" />
                </template>
                {{ !loading ? $t('rent_a_car.button.search') : $t('rent_a_car.button.loading') }}
            </VButton>
        </fieldset>
        <p v-if="errorMessage" :class="$style.error" class="error-message">{{ errorMessage }}</p>
    </form>
    <VNotificationContent v-else icon-name="warning" :title="$t('error')" :content="fetchError" class="box-content">
        <VButton tag="a" :label="$t('reload_page')" :href="baseURL" filled theme="yellow" :target="undefined">
            <template #icon>
                <SvgIcon name="refresh" view-box="0 0 24 24" width="24" height="24" />
            </template>
        </VButton>
    </VNotificationContent>
</template>

<style lang="scss" module>
.root {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    border: 0 none;
    border-radius: var(--radius-md);
    background-color: var(--color-white);
    box-shadow: 0 rem(4) rem(74) rgb(0 0 0 / 8%);
}

.title {
    width: 100%;
    flex-shrink: 0;
    padding: rem(20) rem(16) 0;

    @include media('>=md') {
        padding: rem(20) rem(24) 0;
    }
}

.type {
    --v-carousel-root-width: auto;

    margin-top: rem(12);
    margin-left: rem(16);

    @include media('>=md') {
        margin-top: rem(16);
        margin-left: rem(24);
    }
}

.contact {
    order: 2;
    margin: 0 auto rem(16);
    color: #010101;

    @include media('>=md') {
        order: unset;
        border-top: none;
        margin-top: rem(16);
        margin-right: rem(24);
        margin-bottom: 0;
    }

    & span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.main {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    padding: var(--spacing-2-xs);
    border: 0 none;
    border-top: 1px solid rgb(1 1 1 / 10%);
    margin-top: rem(16);
    gap: var(--spacing-sm);

    @include media('>=md') {
        align-items: flex-start;
        justify-content: space-between;
        padding: var(--spacing-sm);
    }

    @include media('>=lg') {
        padding: rem(16) rem(24) rem(24);
    }

    @include media('>=vl') {
        flex-wrap: nowrap;
    }

    // force line break before submit button
    &::before {
        display: block;
        width: 100%;
        flex-shrink: 0;
        order: 2;
        content: '';

        @include media('>=vl') {
            content: none;
        }
    }

    &::after {
        display: block;
        width: 100%;
        height: 1px;
        flex-shrink: 0;
        order: 3;
        background-color: rgb(1 1 1 / 10%);
        content: '';

        @include media('>=md') {
            content: none;
        }
    }
}

.agencies {
    order: 1;
    margin: 0;
}

.submit {
    flex-shrink: 0;
    order: 3;
    margin-top: calc(var(--spacing-sm) * -1); // remove flex gap

    @include media('>=vl') {
        margin-top: calc(12px + 0.5rem); // input label font size + label margin bottom
    }
}

.error {
    padding: var(--spacing-2-xs);
    margin: 0;
    font-size: 14px;

    .dates + & {
        border-top: 1px solid rgb(1 1 1 / 10%);
    }
}
</style>
